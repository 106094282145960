import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Link, withPrefix, graphql } from 'gatsby';
import moment from 'moment';
import { FaAngleDown } from 'react-icons/fa';
import SEO from '../components/seo';
import { GatsbyImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import { removeHTMLTags, flatten } from '../components/common/functions';
import {
  COLOR_DARK_GREEN,
  COLOR_BORDER_GRAY,
  MAX_WIDTH,
  COLOR_GRAY,
  screen,
  COLOR_TEXT_GRAY,
  StyledLink,
  Button,
} from '../components/common/variables';

const Wrapper = styled.div`
  margin: 0 auto 60px 0;
  @media ${screen.largeXL} {
    margin: 90px auto;
  }

  .blogs {
    max-width: ${MAX_WIDTH};
    margin: 0 auto;
    padding: 54px 35px 0 35px;
    @media ${screen.largeXL} {
      padding: 0 35px 0 35px;
    }
    @media ${screen.largeXXL} {
      padding: 0;
    }

    .read-more {
      text-align: center;
      width: calc(100% - 70px);
      @media ${screen.medium} {
        width: auto;
        text-align: left;
      }
    }

    &__page-title {
      color: ${COLOR_GRAY};
      font-size: 1.8rem;
      font-weight: 400;
      margin: 0;
      @media ${screen.medium} {
        font-size: 2.1rem;
      }
      @media ${screen.largeXL} {
        font-size: 2.2rem;
      }
    }

    &__green-text {
      color: ${COLOR_DARK_GREEN};
    }

    &__filters {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      flex-direction: column;
      margin-top: 30px;
      @media ${screen.large} {
        flex-direction: row;
      }
      label {
        font-weight: 100;
        margin-right: 10px;
        font-size: 0.9rem;
        opacity: 0.7;
        display: block;
        margin-bottom: 10px;
        color: ${COLOR_TEXT_GRAY};
        @media ${screen.large} {
          display: inline-block;
          opacity: 1;
          font-size: 1.1rem;
        }
      }
    }

    &__each-filter-container {
      position: relative;
      margin: 0 0 26px 0;

      @media ${screen.largeXL} {
        margin: 0;
      }
      select {
        appearance: none;
        border-radius: 0;
        padding: 10px 15px 10px 15px;
        box-shadow: 0;
        border: 1px solid ${COLOR_BORDER_GRAY};
        background-color: transparent;
        color: ${COLOR_GRAY};
        line-height: 1.25;
        font-weight: 400;
        font-size: 0.9rem;
        cursor: pointer;
        outline: none;
        width: 100%;
        @media ${screen.large} {
          width: 200px;
        }

        @media ${screen.largeXL} {
          opacity: 1;
          width: 238px;
          padding: 10px 15px 10px 15px;
        }
      }
      .down-icon {
        position: absolute;
        right: 10px;
        top: 59%;
        pointer-events: none;
        color: ${COLOR_GRAY};
        cursor: pointer;
        z-index: 2;
        @media screen and (-ms-high-contrast: active),
          (-ms-high-contrast: none) {
          opacity: 0;
        }
        @media ${screen.medium} {
          top: 50%;
        }
        @media ${screen.large} {
          transform: translateY(-50%);
        }
      }
    }

    &__blog-list {
      padding: 0;
      margin: 0;
      @media ${screen.largeXL} {
        margin: 90px 0;
      }
    }

    &__blog-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-bottom: 40px;
      @media ${screen.largeXL} {
        flex-wrap: nowrap;
        margin: 80px 0;
        border-bottom: 0;
        padding-bottom: 0;
      }
    }

    &__blog-title {
      font-weight: 400;
      color: ${COLOR_DARK_GREEN};
      font-size: 1.25rem;
      margin-top: 12px;
      transition: all 0.28s ease-out;
      max-width: 500px;
      &:hover {
        color: ${COLOR_GRAY};
      }
      @media ${screen.medium} {
        font-size: 1.45rem;
      }
      @media ${screen.largeXL} {
        font-size: 1.65rem;
        margin-top: 0;
      }
    }

    &__blog-excerpt {
      font-size: 1rem;
      color: ${COLOR_TEXT_GRAY};
      margin-bottom: 12px;

      @media ${screen.medium} {
        margin-bottom: 20px;
        font-size: 1.1rem;
      }
    }

    &__blog-texts-container {
      max-width: 345px;
      overflow: hidden;
      @media ${screen.medium} {
        max-width: 345px;
      }
      @media ${screen.largeXL} {
        max-width: 715px;
      }
    }

    &__blog-created-at {
      color: ${COLOR_TEXT_GRAY};
      font-weight: 100;
      font-size: 0.87rem;
      opacity: 0.8;
      margin: 5px 0 10px 0;
    }

    &__blog-img {
      width: 100%;
      margin: 1rem 0 0.3rem 0;
      order: -1;
      align-self: center;
      text-align: center;
      @media ${screen.large} {
        width: 370px;
      }
      @media ${screen.largeXL} {
        margin: 0;
        order: 1;
      }
    }

    &__load-more-btn {
      cursor: pointer;
      outline: none;
      padding-left: 2.9rem;
      padding-right: 2.9rem;
      margin-top: 60px;
      width: 100%;
      @media ${screen.medium} {
        width: auto;
      }
      @media ${screen.largeXL} {
        margin-top: 20px;
      }
    }
  }
`;

class BlogPage extends Component {
  allBlogs = this.props.data.blogs.edges.reduce((a, c, i) => {
    // changing the date format in the original blog list
    c.node._date = moment(c.node.date).format('MMM YYYY');
    a[i] = c;
    return a;
  }, []);
  state = {
    blogLength: 6, // initial blog length if filtered by all months and categories
    blogs: this.allBlogs,
    selectedCategory: 'All categories',
    selectedDateCreated: 'All months',
    dates: Array.from(
      new Set(this.props.data.blogs.edges.map((blog) => blog.node._date))
    ),
    categories: Array.from(
      new Set(
        flatten(
          this.props.data.blogs.edges.map((blog) => [
            ...blog.node.categories.nodes.map((category) => category.name),
          ])
        )
      )
    ),
  };

  filterByCategory = (e) => {
    const filterDateCreated = [];
    const filterCategories = [];
    const { selectedDateCreated } = this.state;

    if (
      e.target.value === 'All categories' &&
      selectedDateCreated === 'All months'
    ) {
      const filteredBlogs = this.allBlogs;
      filteredBlogs.forEach((blog) => {
        if (filterDateCreated.indexOf(blog.node._date) === -1) {
          filterDateCreated.push(blog.node._date);
        }
      });
      this.setState({
        blogs: filteredBlogs,
        dates: filterDateCreated,
        selectedCategory: e.target.value,
      });
    }

    if (
      e.target.value !== 'All categories' &&
      selectedDateCreated !== 'All months'
    ) {
      const filteredBlogs = this.allBlogs.filter((blog) => {
        return (
          blog.node.categories.nodes.some(
            (category) => category.name === e.target.value
          ) && blog.node._date === selectedDateCreated
        );
      });

      filteredBlogs.forEach((blog) => {
        if (filterDateCreated.indexOf(blog.node._date) === -1) {
          filterDateCreated.push(blog.node._date);
        }
      });
      this.setState({
        blogs: filteredBlogs,
        dates: filterDateCreated,
        selectedCategory: e.target.value,
      });
    }

    if (
      e.target.value !== 'All categories' &&
      selectedDateCreated === 'All months'
    ) {
      const filteredBlogs = this.allBlogs.filter((blog) => {
        return blog.node.categories.nodes.some(
          (category) => category.name === e.target.value
        );
      });
      filteredBlogs.forEach((blog) => {
        if (filterDateCreated.indexOf(blog.node._date) === -1) {
          filterDateCreated.push(blog.node._date);
        }
      });
      filteredBlogs.forEach((blog) => {
        blog.node.categories.nodes.forEach((category) => {
          if (filterCategories.indexOf(category.name) === -1) {
            filterCategories.push(category.name);
          }
        });
      });
      this.setState({
        blogs: filteredBlogs,
        dates: filterDateCreated,
        selectedCategory: e.target.value,
        categories: filterCategories,
      });
    }

    if (
      e.target.value === 'All categories' &&
      selectedDateCreated !== 'All months'
    ) {
      const filteredBlogs = this.allBlogs.filter((blog) => {
        return blog.node._date === selectedDateCreated;
      });
      filteredBlogs.forEach((blog) => {
        if (filterDateCreated.indexOf(blog.node._date) === -1) {
          filterDateCreated.push(blog.node._date);
        }
      });
      filteredBlogs.forEach((blog) => {
        blog.node.categories.nodes.forEach((category) => {
          if (filterCategories.indexOf(category.name) === -1) {
            filterCategories.push(category.name);
          }
        });
      });
      this.setState({
        blogs: filteredBlogs,
        dates: filterDateCreated,
        selectedCategory: e.target.value,
        selectedDateCreated,
        categories: filterCategories,
      });
    }
  };

  //------------------------

  filterByDateCreated = (e) => {
    const { selectedCategory } = this.state;
    const filterCategories = [];
    const filterDateCreated = [];
    if (
      e.target.value === 'All months' &&
      selectedCategory === 'All categories'
    ) {
      const filteredBlogs = this.allBlogs;
      filteredBlogs.forEach((blog) => {
        blog.node.categories.nodes.forEach((category) => {
          if (filterCategories.indexOf(category.name) === -1) {
            filterCategories.push(category.name);
          }
        });
      });
      filteredBlogs.forEach((blog) => {
        if (filterDateCreated.indexOf(blog.node._date) === -1) {
          filterDateCreated.push(blog.node._date);
        }
      });
      this.setState({
        blogs: filteredBlogs,
        categories: filterCategories,
        selectedDateCreated: e.target.value,
        dates: filterDateCreated,
      });
    }

    if (
      e.target.value !== 'All months' &&
      selectedCategory !== 'All categories'
    ) {
      const filteredBlogs = this.allBlogs.filter((blog) => {
        return (
          blog.node._date === e.target.value &&
          blog.node.categories.nodes.some(
            (category) => category.name === selectedCategory
          )
        );
      });

      filteredBlogs.forEach((blog) => {
        blog.node.categories.nodes.forEach((category) => {
          if (filterCategories.indexOf(category.name) === -1) {
            filterCategories.push(category.name);
          }
        });
      });
      this.setState({
        blogs: filteredBlogs,
        categories: filterCategories,
        selectedDateCreated: e.target.value,
      });
    }

    if (
      e.target.value === 'All months' &&
      selectedCategory !== 'All categories'
    ) {
      const filteredBlogs = this.allBlogs.filter((blog) => {
        return blog.node.categories.nodes.some(
          (category) => category.name === selectedCategory
        );
      });
      filteredBlogs.forEach((blog) => {
        blog.node.categories.nodes.forEach((category) => {
          if (filterCategories.indexOf(category.name) === -1) {
            filterCategories.push(category.name);
          }
        });
      });
      this.setState({
        blogs: filteredBlogs,
        categories: filterCategories,
        selectedDateCreated: e.target.value,
      });
    }

    if (
      e.target.value !== 'All months' &&
      selectedCategory === 'All categories'
    ) {
      const filteredBlogs = this.allBlogs.filter((blog) => {
        return blog.node._date === e.target.value;
      });
      filteredBlogs.forEach((blog) => {
        blog.node.categories.nodes.forEach((category) => {
          if (filterCategories.indexOf(category.name) === -1) {
            filterCategories.push(category.name);
          }
        });
      });
      this.setState({
        blogs: filteredBlogs,
        categories: filterCategories,
        selectedDateCreated: e.target.value,
      });
    }
  };

  render() {
    const {
      blogs,
      dates,
      categories,
      selectedCategory,
      selectedDateCreated,
      blogLength,
    } = this.state;

    const { acfBlogPage } = this.props.data.blogPage;
    const pageTitle =
      (acfBlogPage.seo && acfBlogPage.seo.pageTitle) ||
      'Source Separation Systems Blog';
    const pageDescription = acfBlogPage.seo && acfBlogPage.seo.description;
    return (
      <Layout>
        <SEO
          title={pageTitle}
          description={pageDescription}
          image={withPrefix('/og_images/blog.jpeg')}
        />
        <Wrapper>
          <div className="blogs">
            <h1 className="blogs__page-title">
              <span className="blogs__green-text">Straight from the</span>{' '}
              source
            </h1>
            <div className="blogs__filters">
              <div className="blogs__each-filter-container">
                <FaAngleDown className="down-icon" />
                <label>Select a Category</label>
                <select
                  value={selectedCategory}
                  className="blogs__select-category"
                  onChange={this.filterByCategory}
                >
                  <option>All categories</option>

                  {categories.map((category) => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </div>
              <div className="blogs__each-filter-container">
                <FaAngleDown className="down-icon" />
                <label>Archives</label>
                <select
                  value={selectedDateCreated}
                  onChange={this.filterByDateCreated}
                >
                  <option>All months</option>
                  {dates.map((date, i) => (
                    <option key={i} value={`${date}`}>
                      {date}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <ul className="blogs__blog-list">
              {blogs
                .map((blog, i) => {
                  return (
                    <Fragment key={blog.node.id}>
                      <li className="blogs__blog-container">
                        <div className="blogs__blog-texts-container">
                          <Link to={`/blog/${blog.node.slug}`}>
                            <h2
                              className="blogs__blog-title"
                              dangerouslySetInnerHTML={{
                                __html: blog.node.title,
                              }}
                            />
                          </Link>
                          <p className="blogs__blog-created-at">
                            {moment(blog.node.date).format('MMMM DD YYYY')}
                          </p>
                          <p className="blogs__blog-excerpt">
                            {removeHTMLTags(blog.node.acfBlog.content)
                              .split(' ')
                              .slice(0, 30)
                              .join(' ') + '...'}
                          </p>
                          <StyledLink
                            className="read-more"
                            to={`/blog/${blog.node.slug}`}
                          >
                            Read more
                          </StyledLink>
                        </div>
                        {blog.node.acfBlog.featuredImage && (
                          <div className="blogs__blog-img">
                            <Link to={`/blog/${blog.node.slug}`}>
                              <GatsbyImage
                                className="large-banner"
                                image={
                                  blog.node.acfBlog.featuredImage.localFile
                                    .childImageSharp.gatsbyImageData
                                }
                                alt={
                                  blog.node.acfBlog.featuredImage.altText ||
                                  'Source Separation Systems'
                                }
                              />
                            </Link>
                          </div>
                        )}
                      </li>
                    </Fragment>
                  );
                })
                .slice(0, blogLength)}
            </ul>
            {blogs.length >= blogLength ? (
              <Button
                className="blogs__load-more-btn"
                transparent
                onClick={() =>
                  this.setState({
                    blogLength: blogLength + 6,
                  })
                }
              >
                Load More
              </Button>
            ) : null}
          </div>
        </Wrapper>
      </Layout>
    );
  }
}

export default BlogPage;

export const blogQuery = graphql`
  {
    blogPage: wpPage(uri: { eq: "/blog-page/" }) {
      id
      acfBlogPage {
        seo {
          description
          pageTitle
        }
      }
    }
    blogs: allWpPost(sort: { fields: date, order: DESC }) {
      edges {
        node {
          id
          slug
          title
          date
          acfBlog {
            content
            featuredImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 600, height: 405)
                }
              }
            }
          }
          categories {
            nodes {
              name
            }
          }
        }
      }
    }

    categories: allWpCategory {
      edges {
        node {
          name
        }
      }
    }
  }
`;
